import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pagination"
    }}>{`Pagination`}</h1>
    <p>{`Documentation and examples for showing pagination to indicate a series of related content exists across multiple pages.`}</p>
    <div className='mb-3'>
  <ul className="pagination">
    <li className="disabled">
      <a href="#" onclick="return false;">
        <i className="fas fa-chevron-left"></i>
      </a>
    </li>
    <li>
      <a href="#" onclick="return false;">1</a>
    </li>
    <li className='active'>
      <a href="#" onclick="return false;">2</a>
    </li>
    <li>
      <a href="#" onclick="return false;">3</a>
    </li>
    <li>
      <a href="#" onclick="return false;">
        <i className="fas fa-chevron-right"></i>
      </a>
    </li>
  </ul>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class='mb-3'>
  <ul class="pagination">
    <li class="disabled">
      <a>
        <i class="fas fa-chevron-left"></i>
      </a>
    </li>
    <li>
      <a>1</a>
    </li>
    <li class='active'>
      <a>2</a>
    </li>
    <li>
      <a>3</a>
    </li>
    <li>
      <a>
        <i class="fas fa-chevron-right"></i>
      </a>
    </li>
  </ul>
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      